
// 小程序介绍页
let routerStaticPage = [
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      title: '用户协议',
    },
    component: () => import('../views/StaticPage/agreement.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title: '隐私政策',
    },
    component: () => import('../views/StaticPage/privacy.vue'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      title: '产品协议',
    },
    component: () => import('../views/StaticPage/recharge.vue'),
  },
]

export default routerStaticPage;