/*
 * @Descripttion : 路由列表
 */

import routerAboutCar from './routerAboutCar';
import routerMarketing from './routerMarketing';
import routerStaticPage from './routerStaticPage';
import routerSharePage from './routerSharePage';
import routerInstall from './routerInstall';

let routes = [
  ...routerAboutCar,
  ...routerMarketing,
  ...routerStaticPage,
  ...routerSharePage,
  ...routerInstall
];


export default routes;