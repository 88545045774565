
// 分享相关页面
let routerSharePage = [
  {
    path: '/sharesortlist',
    name: 'sharesortlist',
    meta: {
      title: '分享排行榜',
    },
    component: () => import('../views/SharePage/sharesortlist.vue'),
  },
  
]

export default routerSharePage;