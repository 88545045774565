
// 小程序介绍页
let routerMarketing = [
  {
    path: '/videowechatminiappintroduce',
    name: 'videowechatminiappintroduce',
    meta: {
      title: '视频号微店',
    },
    component: () => import('../views/Marketing/VideoWeChatMiniappIntroduce.vue'),
  },
  {
    path: '/douyinminiappintroduce',
    name: 'douyinminiappintroduce',
    meta: {
      title: '直播微店',
    },
    component: () => import('../views/Marketing/DouyinMiniappIntroduce.vue'),
  },
  {
    path: '/kuaishouminiappintroduce',
    name: 'kuaishouminiappintroduce',
    meta: {
      title: '快手微店',
    },
    component: () => import('../views/Marketing/KuaishouMiniappIntroduce.vue'),
  },
  {
    path: '/workweChatminiappintroduce',
    name: 'workweChatminiappintroduce',
    meta: {
      title: '企业微信工具',
    },
    component: () => import('../views/Marketing/WorkWeChatMiniappIntroduce.vue'),
  },
  {
    path: '/autocoverplate',
    name: 'autocoverplate',
    meta: {
      title: '挡车牌',
    },
    component: () => import('../views/Marketing/AutoCoverPlate'),
  }
]

export default routerMarketing;