let aboutCar = [
  {
    path: '/assessmentreport',
    name: 'assessmentreport',
    meta: {
      title: '评估报告详情',
    },
    component: () => import('../views/AboutCar/assessmentReport'),
  },
  {
    path: '/quotation',
    name: 'quotation',
    meta: {
      title: '报价单',
    },
    component: () => import('../views/AboutCar/quotation'),
  },
  {
    path: '/quotationprice',
    name: 'quotationprice',
    meta: {
      title: '报价单',
    },
    component: () => import('../views/AboutCar/vehiclequotationprices'),
  },
  {
    path: '/carconfig',
    name: 'carconfig',
    meta: {
      title: '车辆配置',
    },
    component: () => import('../views/AboutCar/carconfig'),
  }
]

export default aboutCar