<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-style: normal;
}
body{
  font-family:PingFang-SC-Regular,”Microsoft YaHei”,Arial,Helvetica,sans-serif,”宋体”;
}
@font-face {
  font-family: 'number_iconfont';
  src: url('https://images.carwins.com/erp-saas-manage-static/app-static/app-icon/fontFamily/ranking.ttf') format('truetype');
}
</style>
