// 手机安装介绍
let routerInstall = [
  {
    path: '/huaweiinstall',
    name: 'huaweiinstall',
    meta: {
      title: '华为安装图示',
    },
    component: () => import('../views/InstallIntro/HuaWeiInstall.vue'),
  },
  
]

export default routerInstall;